import { EConditionSlug } from '~/types/vehicle'

/**
 * Anonymous middleware. Should be enabled on any page using /new or /used
 */
export default defineNuxtRouteMiddleware(({ params }) => {
  if (
    params?.condition !== EConditionSlug.New &&
    params?.condition !== EConditionSlug.Used
  ) {
    return navigateTo('/new', { redirectCode: 302 })
  }
})
